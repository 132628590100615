import React, { useState } from "react";
import {
  Modal,
  Fade,
  Typography,
  Button,
  TextField,
  CircularProgress,
  Grid,
  Paper,
  Box,
} from "@mui/material";
import { decoratedFetch } from "../../req_utils";
import Alert from "@mui/material/Alert";
import { SUPPORT_EMAIL } from "../../constants";
import { useSelector } from 'react-redux';

export function CreateAccountModal({
  isOpen,
  handleClose,
  triggerParentUpdate,
}) {
  const [accountName, setAccountName] = useState("");
  const [addressLn1, setAddressLn1] = useState("");
  const [addressLn2, setAddressLn2] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zip, setZip] = useState("");
  const [loading, setLoading] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [severity, setSeverity] = useState("");
  const employee = useSelector((state) => state.employee); 

  const handleAccountNameChange = (event) => setAccountName(event.target.value);
  const handleAddressLn1Change = (event) => setAddressLn1(event.target.value);
  const handleAddressLn2Change = (event) => setAddressLn2(event.target.value);
  const handleCityChange = (event) => setCity(event.target.value);
  const handleStateChange = (event) => setState(event.target.value);
  const handleZipChange = (event) => setZip(event.target.value);

  // Function to reset all form fields
  const resetFormFields = () => {
    setAccountName("");
    setAddressLn1("");
    setAddressLn2("");
    setCity("");
    setState("");
    setZip("");
    setLoading(false);
    setAlertMessage("");
    setSeverity("");
  };

  // Extended handleClose function that resets the fields
  const extendedClose = () => {
    handleClose(); // Call the original handleClose to manage the modal state
    resetFormFields(); // Reset form fields after closing
  };

  const handleSend = () => {
    setLoading(true);
    if (!accountName || !addressLn1 || !city || !state || !zip) {
      setSeverity("error"); //Set alert type
      setAlertMessage(
        "Required fields not filled in. Contacts must have the following: Type, First Name, Last Name",
      );
      setLoading(false); // Stop loading in case of error
      setTimeout(() => setAlertMessage(""), 2500);
      return;
    }
    // Prepare the payload
    const payload = {
      name: accountName,
      line_1: addressLn1,
      line_2: addressLn2,
      city: city,
      state: state,
      zip: zip,
      company_id: employee.company.id,
    };
    // Make the POST request
    decoratedFetch("/create_account", {
      method: "POST",
      body: JSON.stringify(payload),
    })
      .then((response) => {
        switch (response.status) {
          case 201:
            return response.json();
          case 400:
            setAlertMessage("Required fields not filled in.");
            break;
          case 500:
            setAlertMessage(
              `Internal server error. If this persists, please contact ${SUPPORT_EMAIL}`,
            );
            break;
          default:
            setAlertMessage(
              `Unknown error. If this persists, please contact ${SUPPORT_EMAIL}`,
            );
        }
        throw new Error("Error message generated");
      })
      .then((data) => {
        setLoading(false); // Stop loading
        setSeverity("success"); //set alert type
        setAlertMessage("Account created"); // Set success message
        triggerParentUpdate();
        setTimeout(() => handleClose(), 2000); // Close the modal after successful operation
      })
      .catch((error) => {
        console.error("Error:", error); //Log the error
        setSeverity("error"); //Set alert type
        setLoading(false); // Stop loading in case of error
      });
  };

  const handleAlertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setAlertMessage(""); // Clear success message
  };

  return (
    <Modal
      disableScrollLock
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
      open={isOpen}
      onClose={() => {
        extendedClose();
        setAlertMessage(""); // Also clear success message when closing
      }}
      closeAfterTransition
    >
      <Fade in={isOpen}>
        <Paper
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 1000,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: 4,
          }}
        >
          {alertMessage && (
            <Alert
              onClose={handleAlertClose}
              severity={severity}
              sx={{ width: "100%", mb: 2 }}
            >
              {alertMessage}
            </Alert>
          )}
          <Box
            sx={{
              gap: 1,
              display: "flex",
              flexDirection: "column",
              padding: 0,
            }}
          >
            <Typography variant="h6" sx={{ paddingBottom: 1 }}>
              Create New Account
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  label="Account Name"
                  fullWidth
                  variant="outlined"
                  value={accountName}
                  onChange={handleAccountNameChange}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Address Line 1"
                  fullWidth
                  variant="outlined"
                  value={addressLn1}
                  onChange={handleAddressLn1Change}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Address Line 2"
                  fullWidth
                  variant="outlined"
                  value={addressLn2}
                  onChange={handleAddressLn2Change}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="City"
                  fullWidth
                  variant="outlined"
                  value={city}
                  onChange={handleCityChange}
                />
              </Grid>
              <Grid item xs={2}>
                <TextField
                  label="State"
                  fullWidth
                  variant="outlined"
                  value={state}
                  onChange={handleStateChange}
                  inputProps={{ maxLength: 2 }}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  label="Zip Code"
                  fullWidth
                  variant="outlined"
                  value={zip}
                  inputProps={{ maxLength: 5 }}
                  onChange={handleZipChange}
                />
              </Grid>
            </Grid>
            {loading ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  width: "100%",
                  paddingTop: 10,
                }}
              >
                <CircularProgress
                  size="25px"
                  sx={{
                    textAlign: "center",
                    mt: "6x",
                    mb: "5.5px",
                    mr: "20px",
                  }}
                />
              </div>
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  width: "100%",
                  paddingTop: 10,
                }}
              >
                <Button
                  style={{ marginRight: 10 }}
                  variant="outlined"
                  onClick={handleClose}
                  disabled={alertMessage}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSend}
                  disabled={alertMessage}
                >
                  Create
                </Button>
              </div>
            )}
          </Box>
        </Paper>
      </Fade>
    </Modal>
  );
}
