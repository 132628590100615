import React from "react";
import { Paper, Grid } from "@mui/material";
import ProjectItemsTabs from "./ProjectItemsTabs";
import ProposalSummaryGrid from "./ProposalSummaryGrid";
import ProposalRibbon from "./ProposalRibbon";

const ProposalDetails = () => {
  return (
    <Grid container spacing={2} sx={{ pb: 2.5 }}>
      <Grid
        item
        xs={12}
        sx={{ paddingTop: 0, px: 2, py: 2, width: "100%", minHeight: "90px" }}
      >
        <ProposalRibbon />
      </Grid>
      <Grid item xs={12} lg={9}>
        <Paper
          elevation={3}
          sx={{
            paddingTop: 0,
            px: 2,
            paddingBottom: 2,
            width: "100%",
            height: "642px",
            mb: "-20px",
          }}
        >
          <ProjectItemsTabs />
        </Paper>
      </Grid>
      <Grid
        item
        xs={12}
        lg={3}
        sx={{
          mt: { xs: "20px", lg: 0 },
          px: 2,
          width: "100%",
          mb: "-20px",
        }}
      >
        <ProposalSummaryGrid />
      </Grid>
    </Grid>
  );
};

export default ProposalDetails;
