const initialState = {
  authenticated: false,
  employee: {},
  project: {},
  refreshFiles: false,
  refreshProposalCustomers: false,
  refreshProposalLocations: false,
  proposals: [],
  selectedProposal: {},
};

export const initializeProject = (project) => ({
  type: "initialize_project",
  payload: project,
});

export const resetProject = () => ({
  type: "reset_project",
});

export const updateProject = (project) => ({
  type: "update_project",
  payload: project,
});

export const updateProjectKeepClean = (project) => ({
  type: "update_project_keep_clean",
  payload: project,
});

export const markProjectClean = () => ({
  type: "mark_project_clean",
});

export const refreshFiles = (refresh) => ({
  type: "refresh_files",
  payload: refresh,
});

export const selectProposal = (proposalId) => ({
  type: "select_proposal",
  payload: proposalId,
});

export const selectProposalAndRefreshProposals = (proposal) => ({
  type: "select_proposal_and_refresh",
  payload: proposal,
});

export const updateProposals = (proposals) => ({
  type: "update_proposals",
  payload: proposals,
});

export const updateSelectedProposal = (proposal) => ({
  type: "update_selected_proposal",
  payload: proposal,
});

export const updateSelectedProposalKeepClean = (proposal) => ({
  type: "update_selected_proposal_keep_clean",
  payload: proposal,
});

export const markSelectedProposalClean = () => ({
  type: "mark_selected_proposal_clean",
});

export const refreshProposalCustomers = (refresh) => ({
  type: "refresh_proposal_customers",
  payload: refresh,
});

export const refreshProposalLocations = (refresh) => ({
  type: "refresh_proposal_locations",
  payload: refresh,
});

export const updateAccount = (account) => ({
  type: "update_account",
  payload: account,
});

export const updateEmployee = (employee) => ({
  type: "update_employee",
  payload: employee,
});

export default function rootReducer(state = initialState, action) {
  let newState = {};
  switch (action.type) {
    case "update_employee":
      newState.employee = action.payload;
      break;
    case "mark_as_authenticated":
      newState.employee = action.employee;
      newState.authenticated = true;
      break;
    case "mark_as_unauthenticated":
      newState = initialState;
      break;
    case "initialize_project":
      newState.project = action.payload;
      newState.project.isDirty = false;
      break;
    case "reset_project":
      return {
        ...state,
        project: initialState.project,
      };
    case "mark_project_clean":
      state.project.isDirty = false;
      return state;
    case "update_project":
      newState.project = action.payload;
      newState.project.isDirty = true;
      break;
    case "update_project_keep_clean":
      newState.project = action.payload;
      newState.project.isDirty = false;
      break;
    case "refresh_files":
      newState.refreshFiles = action.payload;
      break;
    case "select_proposal":
      newState.selectedProposal = state.proposals.find(
        (proposal) => proposal.id === action.payload,
      );
      newState.refreshProposals = false;
      break;
    case "select_proposal_and_refresh":
      state.selectedProposal = action.payload;
      state.proposals.splice(1, 0, action.payload);
      return state;
    case "update_proposals":
      newState.proposals = action.payload;
      break;
    case "mark_selected_proposal_clean":
      state.selectedProposal.isDirty = false;
      return state;
    case "update_selected_proposal":
      newState.selectedProposal = action.payload;
      newState.proposals = state.proposals.map((proposal) =>
        proposal.id === action.payload.id ? action.payload : proposal,
      );
      newState.selectedProposal.isDirty = true;
      break;
    case "update_selected_proposal_keep_clean":
      newState.selectedProposal = action.payload;
      newState.proposals = state.proposals.map((proposal) =>
        proposal.id === action.payload.id ? action.payload : proposal,
      );
      newState.selectedProposal.isDirty = false;
      break;
    case "refresh_proposal_customers":
      newState.refreshProposalCustomers = action.payload;
      break;
    case "refresh_proposal_locations":
      newState.refreshProposalLocations = action.payload;
      break;
    case "update_account":
      newState.project = state.project;
      newState.project.account = action.payload;
      break;
    default:
      console.log(`Unknown redux action type: ${action.type}`);
  }
  return { ...state, ...newState };
}
