import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Divider,
  Button,
  TextField,
  CircularProgress,
  Grid,
  Box,
  Slide,
  Snackbar,
  IconButton,
} from "@mui/material";
import Alert from "@mui/material/Alert";
import { decoratedFetch } from "../../req_utils";
import { SUPPORT_EMAIL } from "../../constants";
import ContactsTab from "../ContactsTab";
import { GENESIS_LOGO_COLOR } from "../../constants";
import ClientInformationTabs from "../ClientInformationTabs";
import CloseIcon from "@mui/icons-material/Close";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function EditAccountDialog({
  isOpen,
  handleClose,
  triggerParentUpdate,
  account,
}) {
  const [accountName, setAccountName] = useState(account?.name || "");
  const [addressLn1, setAddressLn1] = useState(account?.address?.line_1 || "");
  const [addressLn2, setAddressLn2] = useState(account?.address?.line_2 || "");
  const [city, setCity] = useState(account?.address?.city || "");
  const [state, setState] = useState(account?.address?.state || "");
  const [zip, setZip] = useState(account?.address?.zip || "");
  const [loading, setLoading] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [severity, setSeverity] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [isDirty, setIsDirty] = useState(false);

  const handleCityChange = (event) => {
    setIsDirty(true);
    setCity(event.target.value);
  };
  const handleStateChange = (event) => {
    setIsDirty(true);
    setState(event.target.value);
  };
  const handleZipChange = (event) => {
    setIsDirty(true);
    setZip(event.target.value);
  };
  const handleAccountNameChange = (event) => {
    setIsDirty(true);
    setAccountName(event.target.value);
  };
  const handleAddressLn1Change = (event) => {
    setIsDirty(true);
    setAddressLn1(event.target.value);
  };
  const handleAddressLn2Change = (event) => {
    setIsDirty(true);
    setAddressLn2(event.target.value);
  };

  useEffect(() => {
    setAccountName(account?.name || "");
    setAddressLn1(account?.address?.line_1 || "");
    setAddressLn2(account?.address?.line_2 || "");
    setCity(account?.address?.city || "");
    setState(account?.address?.state || "");
    setZip(account?.address?.zip || "");
  }, [account]);

  const handleSend = () => {
    setLoading(true);
    if (!accountName || !addressLn1 || !city || !state || !zip) {
      setSeverity("error");
      setAlertMessage("Required fields not filled in.");
      setLoading(false);
      setTimeout(() => setAlertMessage(""), 2500);
      return;
    }

    const payload = {
      name: accountName,
      address: {
        line_1: addressLn1,
        line_2: addressLn2,
        city: city,
        state: state,
        zip: zip,
      },
    };

    decoratedFetch(`/update_account/${account.id}`, {
      method: "PUT",
      body: JSON.stringify(payload),
    })
      .then((response) => {
        switch (response.status) {
          case 200:
            return response.json();
          case 400:
            setAlertMessage("Required fields not filled in.");
            break;
          case 500:
            setAlertMessage(
              `Internal server error. If this persists, please contact ${SUPPORT_EMAIL}`,
            );
            break;
          default:
            setAlertMessage(
              `Unknown error. If this persists, please contact ${SUPPORT_EMAIL}`,
            );
        }
        throw new Error("Error message generated");
      })
      .then((data) => {
        setIsDirty(false);
        setLoading(false);
        setSnackbarOpen(true);
        setSnackbarMessage("Account details saved.");
        triggerParentUpdate({
          id: account.id,
          name: accountName,
          address: {
            line_1: addressLn1,
            line_2: addressLn2,
            city: city,
            state: state,
            zip: zip,
          },
        });
      })
      .catch((error) => {
        console.error("Error:", error);
        setSeverity("error");
        setLoading(false);
      });
  };

  const handleAlertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setAlertMessage("");
  };

  return (
    <>
      <Snackbar
        sx={{
          ".MuiSnackbarContent-root": {
            backgroundColor: GENESIS_LOGO_COLOR,
            minWidth: 0,
          },
        }}
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
      />
      {alertMessage ? (
        <Box display="flex" justifyContent="center" alignItems="center">
          <Alert
            onClose={handleAlertClose}
            severity={severity}
            sx={{ textAlign: "center", width: "70%", borderRadius: 8 }}
          >
            {alertMessage}
          </Alert>
        </Box>
      ) : null}
      <Dialog
        fullScreen
        open={isOpen}
        onClose={handleClose}
        TransitionComponent={Transition}
        aria-labelledby="edit-account-dialog-title"
      >
        <DialogTitle id="edit-account-dialog-title">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "5px",
            }}
          >
            <div>Edit Account Details</div>
            <div>
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleClose}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
            </div>
          </div>
        </DialogTitle>
        <DialogContent>
          {alertMessage && (
            <Alert
              onClose={handleAlertClose}
              severity={severity}
              sx={{ width: "100%", mb: 2 }}
            >
              {alertMessage}
            </Alert>
          )}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 2,
              paddingTop: 2,
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  label="Account Name"
                  fullWidth
                  variant="outlined"
                  value={accountName}
                  onChange={handleAccountNameChange}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Address Line 1"
                  fullWidth
                  variant="outlined"
                  value={addressLn1}
                  onChange={handleAddressLn1Change}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Address Line 2"
                  fullWidth
                  variant="outlined"
                  value={addressLn2}
                  onChange={handleAddressLn2Change}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="City"
                  fullWidth
                  variant="outlined"
                  value={city}
                  onChange={handleCityChange}
                />
              </Grid>
              <Grid item xs={2}>
                <TextField
                  label="State"
                  fullWidth
                  variant="outlined"
                  value={state}
                  onChange={handleStateChange}
                  inputProps={{ maxLength: 2 }}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  label="Zip Code"
                  fullWidth
                  variant="outlined"
                  value={zip}
                  inputProps={{ maxLength: 5 }}
                  onChange={handleZipChange}
                />
              </Grid>
            </Grid>
            {loading ? (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  pt: 2,
                  height: "54px",
                }}
              >
                <CircularProgress size="30px" sx={{ mr: "19px", mt: "4px" }} />
              </Box>
            ) : (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  pt: 2,
                  height: "54px",
                }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSend}
                  disabled={!isDirty}
                >
                  Save
                </Button>
              </Box>
            )}
            <Divider sx={{ paddingTop: 2 }} />
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <ClientInformationTabs
                  account_id={account?.id}
                  canDelete={true}
                />
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
}
