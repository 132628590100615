import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Paper from "@mui/material/Paper";
import {
  Box,
  IconButton,
  Grid,
  TextField,
  FormControl,
  MenuItem,
  Select,
  InputLabel,
  Button,
  Stepper,
  Step,
  StepLabel,
  Snackbar,
} from "@mui/material";
import { decoratedFetch } from "../req_utils";
import { updateAccount, markProjectClean, updateProject } from "../reducers";
import EditIcon from "@mui/icons-material/Edit";
import EditAccountDialog from "./dialogs/EditAccountDialog";
import { PROJECT_STATUSES, GENESIS_LOGO_COLOR } from "../constants";

const ProjectRibbon = () => {
  const project = useSelector((state) => state.project);
  const [saveButtonDisabled, setSaveButtonDisabled] = useState(true);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const [error, setError] = useState(false);
  const [employees, setEmployees] = useState([]);
  const [localProjectName, setLocalProjectName] = useState(project?.name);
  const [trigger, setTrigger] = useState(false);
  const { project_id } = useParams();
  const dispatchState = useDispatch();
  const [accountDialogOpen, setAccountDialogOpen] = useState(false);
  const [accountHover, setAccountHover] = useState(false);

  const handleLocalProjectNameChange = (event) =>
    setLocalProjectName(event.target.value);

  const handleStatusChange = (index) => {
    const newStatus = { id: index + 1, name: PROJECT_STATUSES[index] };
    updateProjectStatusState(newStatus);
  };

  const handleProjectNameBlur = () => {
    dispatchState(updateProject({ ...project, name: localProjectName }));
  };

  const handlePMChange = (event) => {
    updateProjectPMState(event);
  };

  const handleSRChange = (event) => {
    updateProjectSRState(event);
  };

  const handleAssigneeChange = (event) => {
    updateProjectAssigneeState(event);
  };

  const updateProjectStatusState = (newStatus) => {
    const updatedProject = {
      ...project,
      status: newStatus,
    };
    dispatchState(updateProject(updatedProject));
  };

  const updateProjectPMState = (event) => {
    const updatedProject = {
      ...project,
      pm_employee: event.target.value,
    };
    dispatchState(updateProject(updatedProject));
  };

  const updateProjectSRState = (event) => {
    const updatedProject = {
      ...project,
      sales_employee: event.target.value,
    };
    dispatchState(updateProject(updatedProject));
  };

  const updateProjectAssigneeState = (event) => {
    const updatedProject = {
      ...project,
      assignee: event.target.value,
    };
    dispatchState(updateProject(updatedProject));
  };

  useEffect(() => {
    setLocalProjectName(project?.name || "");
    setSaveButtonDisabled(!project?.isDirty);
  }, [project]);

  const triggerParentUpdate = () => {
    setTrigger((prev) => !prev); // Toggle the state to force re-render
  };

  useEffect(() => {
    decoratedFetch("/list_employees")
      .then((response) => {
        if (response.status !== 200) {
          throw new Error("Unknown error");
        }
        return response.json();
      })
      .then((data) => setEmployees(data.filter((employee) => employee.enabled)))
      .catch((error) => {
        console.error(`Error listing employees: ${error}`);
      });
  }, []);

  const handleSave = () => {
    if (!project?.name) {
      setSnackbarOpen(true);
      setSnackbarMessage("Project name required");
      return;
    }
    setSaveButtonDisabled(true);

    const payload = {
      ...project,
      status_id: project?.status?.id,
      name: project?.name,
      pm_employee_id: project?.pm_employee?.id,
      start_date: project?.start_date,
      genesis_branch_address_id: project?.genesis_branch_address?.id,
      sales_employee_id: project?.sales_employee?.id,
      measured_by_employee_id: project?.measured_by_employee?.id,
      assignee_id: project?.assignee?.id,
    };

    decoratedFetch(`/update_project/${project_id}`, {
      method: "PUT",
      body: JSON.stringify(payload),
    })
      .then((response) => {
        if (response.status === 200) {
          return;
        }
        throw new Error("Error message generated");
      })
      .then((data) => {
        setSnackbarOpen(true);
        setSnackbarMessage("Project saved");
        dispatchState(markProjectClean());
      })
      .catch((error) => {
        console.error("Error", error);
        setSnackbarOpen(true);
        setSnackbarMessage("Failed to save project");
        setError(true);
        setSaveButtonDisabled(false);
      });
  };

  const handleAccountDialogClose = () => {
    setAccountDialogOpen(false);
  };

  const handleAccountUpdate = (newAccount) => {
    dispatchState(updateAccount(newAccount));
  };

  return (
    <>
      <Snackbar
        sx={{
          ".MuiSnackbarContent-root": {
            backgroundColor: GENESIS_LOGO_COLOR,
            minWidth: 0,
          },
        }}
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
      />
      <EditAccountDialog
        isOpen={accountDialogOpen}
        handleClose={handleAccountDialogClose}
        account={project?.account}
        triggerParentUpdate={handleAccountUpdate}
      />
      <Paper sx={{ px: "20px", py: "8px" }}>
        <Grid container spacing={1} pt={2}>
          <Grid item xs={4} lg={2.1}>
            <TextField
              label="Project Name"
              fullWidth
              size="small"
              variant="outlined"
              value={localProjectName}
              onChange={handleLocalProjectNameChange}
              onBlur={handleProjectNameBlur}
              InputLabelProps={{ shrink: project?.name?.length > 0 }}
            />
          </Grid>
          <Grid item xs={4} lg={1.15}>
            <TextField
              label="ID"
              fullWidth
              size="small"
              variant="outlined"
              value={project?.custom_id || ""}
              disabled
            />
          </Grid>
          <Grid item xs={4} lg={2.1}>
            <Box position="relative">
              <TextField
                label="Account"
                fullWidth
                size="small"
                variant="outlined"
                value={project?.account?.name || ""}
                InputLabelProps={{ shrink: true }}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    backgroundColor: accountHover ? "#ECECEC" : "#ffffff",
                  },
                }}
              />
              <Box
                position="absolute"
                top={0}
                left={0}
                right={0}
                bottom={0}
                zIndex={1}
                onClick={() => setAccountDialogOpen(true)}
                onMouseEnter={() => setAccountHover(true)}
                onMouseLeave={() => setAccountHover(false)}
                style={{ cursor: "pointer" }}
              />
            </Box>
          </Grid>
          <Grid item xs={3.66} lg={1.85}>
            <FormControl
              sx={{
                width: "100%",
              }}
            >
              <InputLabel size="small">Sales Rep.</InputLabel>
              <Select
                id="sales-select"
                value={
                  project?.sales_employee ? project?.sales_employee?.id : ""
                } // Use pm.id for the value
                onChange={handleSRChange}
                label="Sales Rep."
                size="small"
                fullWidth
                renderValue={(selectedId) => {
                  // Display the selected PM's name
                  const selected = employees.find((e) => e.id === selectedId);
                  return selected
                    ? `${selected.first_name} ${selected.last_name}`
                    : "";
                }}
                MenuProps={{ disableScrollLock: true }}
              >
                {employees.map((employee) => (
                  <MenuItem key={employee.id} value={employee}>
                    {employee.first_name} {employee.last_name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={3.66} lg={1.85}>
            <FormControl
              sx={{
                width: "100%",
              }}
            >
              <InputLabel size="small">Project Manager</InputLabel>
              <Select
                id="pm-select"
                value={project?.pm_employee ? project?.pm_employee?.id : ""} // Use pm.id for the value
                onChange={handlePMChange}
                label="Project Manager"
                fullWidth
                size="small"
                renderValue={(selectedId) => {
                  // Display the selected PM's name
                  const selected = employees.find((e) => e.id === selectedId);
                  return selected
                    ? `${selected.first_name} ${selected.last_name}`
                    : "";
                }}
                MenuProps={{ disableScrollLock: true }}
              >
                {employees.map((employee) => (
                  <MenuItem key={employee.id} value={employee}>
                    {employee.first_name} {employee.last_name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={3.66} lg={1.85}>
            <FormControl
              sx={{
                width: "100%",
              }}
            >
              <InputLabel size="small">Assignee</InputLabel>
              <Select
                id="assignee-select"
                value={project?.assignee ? project?.assignee?.id : ""} // Use pm.id for the value
                onChange={handleAssigneeChange}
                label="Assignee"
                size="small"
                fullWidth
                renderValue={(selectedId) => {
                  // Display the selected PM's name
                  const selected = employees.find((e) => e.id === selectedId);
                  return selected
                    ? `${selected.first_name} ${selected.last_name}`
                    : "";
                }}
                MenuProps={{ disableScrollLock: true }}
              >
                {employees.map((employee) => (
                  <MenuItem key={employee.id} value={employee}>
                    {employee.first_name} {employee.last_name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={1}>
            <Button
              sx={{ ml: 0, mt: "1px" }}
              onClick={handleSave}
              size="medium"
              variant="contained"
              color="primary"
              aria-label="save"
              disabled={saveButtonDisabled}
            >
              Save
            </Button>
          </Grid>
          <Grid item xs={12} sx={{ marginTop: 2 }}>
            <Stepper activeStep={project?.status?.id - 1} alternativeLabel>
              {PROJECT_STATUSES.map((label, index) => (
                <Step key={label} onClick={() => handleStatusChange(index)}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
          </Grid>
        </Grid>
      </Paper>
    </>
  );
};

export default ProjectRibbon;
